<template>
    <ul class="list-unstyled pl-100">
        <li>
            <ds-link to="/atoms/color"> Color </ds-link>
        </li>
        <li>
            <ds-link to="/atoms/corners"> Corners </ds-link>
        </li>
        <li>
            <ds-link to="/atoms/icons"> Icons </ds-link>
        </li>
        <li>
            <ds-link to="/atoms/layout"> Layout </ds-link>
        </li>
        <li>
            <ds-link to="/atoms/spacing"> Spacing </ds-link>
        </li>
        <li>
            <ds-link to="/atoms/typography"> Typography </ds-link>
        </li>
    </ul>
</template>
